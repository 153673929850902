/* eslint-disable no-useless-escape */
import Storage from './Storage';
import format from 'date-fns/format';
import { loginTokenStorageItem, systemRoles, caseStatuses, apiUrl, isAppDebug } from 'config/constants';
import pdfCss from './pdf.csstext';

const __RENDER_BLANK__ = '__RENDER_BLANK__';
const RENDER_BLANK_REGEX = new RegExp(__RENDER_BLANK__);

/**
 * Validate email address
 * @param {String} email
 * @returns {Boolean}
 */
export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}

/**
 * Save login token
 * @param {String} loginToken
 */
export function saveLoginToken(loginToken) {
    Storage.setItem(loginTokenStorageItem, loginToken);
}

/**
 * Get login token
 * @returns {String}
 */
export function getLoginToken() {
    return Storage.getItem(loginTokenStorageItem);
}

/**
 * Delete login token
 */
export function deleteLoginToken() {
    Storage.removeItem(loginTokenStorageItem);
}

/**
 * Sets the the authorization token for api requests
 */
export function setApiRequestToken() {
    const token = Storage.getItem(loginTokenStorageItem);

    window.Eloquent.setApiRequestToken(token);
}

/**
 * Get the value of the requested param from the current page url
 * @param {String} queryString - the search query
 * @param {String} param - the requested param
 * @returns {String}
 */
export function getUrlSearchParam(queryString, param) {
    const allQueries = queryString.slice(1).split('&');
    let val = '';

    allQueries.forEach((query) => {
        if (query.indexOf(param) !== -1) {
            val = query.split('=')[1];
        }
    });

    return val;
}

/**
 * Get the role name from system role number
 * @param {Number} role - role number
 * @returns {String}
 */
export function getRole(role) {
    switch (role) {
        case systemRoles.ADMIN: return 'Admin';
        case systemRoles.SECRETARY: return 'Secretary';
        case systemRoles.LAWYER: return 'Lawyer';
        case systemRoles.CLIENT: return 'Client';
        default:
    }
}

/**
 * Get case file status text from case file status number
 * @param {Number} status - status number
 * @returns {String}
 */
export function getCaseStatus(status) {
    switch (status) {
        case caseStatuses.ACTIVE: return 'Active';
        case caseStatuses.CLOSED: return 'Closed';
        default:
    }
}

/**
 * Capitalize a string
 * @param {String} str
 * @returns {String}
 */
export function capitalize(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function getUpdatedLetterhead(profileData, caseFile, masterTemplate, tags) {
    const originalTemplate = masterTemplate;

    const letterheadTags = {
        'hash-solicitor': tags['hash-solicitor'] ? tags['hash-solicitor'] : null,
        'hash-solicitor-email': tags['hash-solicitor-email'] ? tags['hash-solicitor-email'] : null,
        'hash-solicitor-phone': tags['hash-solicitor-phone'] ? tags['hash-solicitor-phone'] : null,
        'hash-solicitor-ext-number': tags['hash-solicitor-ext-number'] ? tags['hash-solicitor-ext-number'] : null,
        'hash-secretary': tags['hash-secretary'] ? tags['hash-secretary'] : null,
        'hash-secretary-email': tags['hash-secretary-email'] ? tags['hash-secretary-email'] : null,
        'hash-secretary-phone': tags['hash-secretary-phone'] ? tags['hash-secretary-phone'] : null,
        'hash-secretary-ext-number': tags['hash-secretary-ext-number'] ? tags['hash-secretary-ext-number'] : null,
        'hash-clientreference': originalTemplate.template_reference_number
            ? tags[originalTemplate.template_reference_number.replace(/#/gi, '').toLowerCase()] : '',
        'hash-registrationnumber': profileData.law_firm.registration_number,
        'hash-datetoday': format(new Date(), 'MMMM DD, YYYY'),
        'hash-referencenumber': caseFile.reference_number,
        'hash-yourref': tags['hash-yourref'] ? tags['hash-yourref'] : null,
        'hash-filerefno': tags['hash-filerefno'] ? tags['hash-filerefno'] : null,
        'hash-clientrefno': tags['hash-clientrefno'] ? tags['hash-clientrefno'] : null
    };

    const letterhead = JSON.parse(profileData.law_firm.letterhead);
    const updatedLetterhead = {};

    if (letterhead) {
        Object.keys(letterhead).forEach(key => {
            const letterheadContent = letterhead[key].map(item => ({
                ...item,
                content: replaceHashtagsPhaseTwo(profileData, caseFile, item.content, letterheadTags)
            }));

            updatedLetterhead[key] = letterheadContent;
        });
    }

    return [letterheadTags, updatedLetterhead];
}

/**
 * Clean content to send in email
 * @param {String} content
 * @param {Object} caseFile
 * @param {Object} tags
 * @returns {String}
 */
export function prepareTemplateForContent(content, caseFile, tags) {
    const data = document.createElement('div');
    let mainContent = content.includes('<html>')
        // get content enclosed in <div></div>
        ? content.match(/<div[^>]*>[\s\S]*<\/div>/gi) !== null
            ? content.match(/<div[^>]*>[\s\S]*<\/div>/gi)
            // if with html but without enclosing </div>
            : `${content}</div>`.match(/<div[^>]*>[\s\S]*<\/div>/gi)
        : content;

    if (!mainContent) {
        return content.replace(/(?:<p[^>]*>&nbsp;<\/p>){5,}/g, '').replace(/(&nbsp;<span)/g, ' <span');
    }

    mainContent = typeof mainContent === 'string' ? mainContent : mainContent[0];

    // replace span to enable editable
    // mainContent.replace()

    data.innerHTML = mainContent.replace(/\\/g, '').replace(/<h(1|2|3|4|5|6)/g, '<p').replace(/h(1|2|3|4|5|6)>/g, 'p>');

    const list = Array.from(data.querySelectorAll('blockquote, p, pre, li, span, strong'));

    if (list.length > 0) {
        let prevParagraph = null;

        list.forEach((el, index) => {
            if (el.style.textAlign === 'left') {
                el.style.textAlign = '';
            }

            if (el.tagName === 'P') {
                // const spaceCount = el.textContent.replace(/[^\xA0]/g, '').length;

                // if (spaceCount > 0 && spaceCount < 15) {
                //     el.style.paddingLeft = `${(spaceCount - 2) * 40}px`;
                // }
                // insert padding for indention
                if (prevParagraph && /(MORTGAGOR|BORROWER|LESSEE|MEMBER)\(S\)/.test(prevParagraph.innerText)) {
                    // el.style.paddingLeft = /LESSEE|MEMBER\(S\)/.test(prevParagraph.innerText) ? '100px' : '120px';
                }

                const children = Array.from(el.children);

                children.forEach(item => {
                    if (item.tagName === 'SPAN') {
                        if (item.style && item.style.fontWeight === 'bold') {
                            const newEl = document.createElement('strong');

                            newEl.innerHTML = item.innerHTML;
                            item.parentNode.replaceChild(newEl, item);
                        }
                    }
                });

                if (el.style && el.style.fontWeight !== 'bold') {
                    el.innerHTML = el.innerHTML.replace(/<\/?span[^>]*>/g, '');
                }

                replaceHashtagsPhaseOne(el, tags, caseFile.clients);
                elementFix(el);

                prevParagraph = el;
            } else if (el.tagName === 'LI') {
                const children = Array.from(el.children);

                children.forEach(item => {
                    if (item.tagName === 'SPAN') {
                        if (item.style && item.style.fontWeight === 'bold') {
                            const newEl = document.createElement('strong');

                            newEl.innerHTML = item.innerHTML;
                            item.parentNode.replaceChild(newEl, item);
                        }
                    }
                });

                if (el.style && el.style.fontWeight !== 'bold') {
                    el.innerHTML = el.innerHTML.replace(/<\/?span[^>]*>/g, '');
                }

                replaceHashtagsPhaseOne(el, tags, caseFile.clients);
                elementFix(el);
            } else if (el.tagName === 'SPAN') {
                if (/\s/.test(el.innerText) && el.innerText.length === 1) {
                    el.remove();
                }

                replaceHashtagsPhaseOne(el, tags, caseFile.clients);

                if (/[-]{10,}/.test(el.innerText)) {
                    el.innerHTML = '<div class="horizontal-rule ck-widget ck-widget_selected" contenteditable="false"></div>';
                }
            }
        });
    }

    return data.innerHTML.replace(/(<\s*p[^>]*>&nbsp;<\/p>){5,}/g, '').replace(/(&nbsp;<span)/g, ' <span');
}

/**
 * Replace hashtags
 * @param {Object} el
 * @param {Object} tags
 * @param {Array} clients
 */
function replaceHashtagsPhaseOne(el, tags, clients) {
    let temp;

    if (el.innerText.toLowerCase().includes('hash-') || el.innerText.toLowerCase().includes('chk-')) {
        const hashKey = el.innerText.toLowerCase();
        let hashtagValue = tags[hashKey.replace(/#/g, '')] ? tags[hashKey.replace(/#/g, '')].replace(/(<br>){2,}/g, '<br>') : '__________';

        isAppDebug && console.log(`PHASE1 HASHTAG: <<${hashKey}>> ===> <<${hashtagValue}>>`);

        if (hashKey.includes('hash-propertyagencyaddress') === true) {
            hashtagValue = '';

            hashtagValue = tags['propery-agency-address-1'] ? `<span id="propery-agency-address-1">${tags['propery-agency-address-1']}</span><br>` : hashtagValue;
            hashtagValue = tags['propery-agency-address-2'] ? `${hashtagValue}<span id="propery-agency-address-2">${tags['propery-agency-address-2']}</span><br>` : hashtagValue;
            hashtagValue = tags['propery-agency-city'] ? `${hashtagValue}<span id="propery-agency-city">${tags['propery-agency-city']}</span>` : hashtagValue;
            hashtagValue = tags['propery-agency-city'] && tags['propery-agency-postal-code'] ? `${hashtagValue} ` : hashtagValue;
            hashtagValue = tags['propery-agency-postal-code'] ? hashtagValue : `${hashtagValue}<br>`;
            hashtagValue = tags['propery-agency-postal-code'] ? `${hashtagValue}<span id="propery-agency-postal-code">${tags['propery-agency-postal-code']}</span><br>` : hashtagValue;
            hashtagValue = tags['propery-agency-country'] ? `${hashtagValue}<span id="propery-agency-country">${tags['propery-agency-country']}</span>` : hashtagValue;

            // el.innerHTML = hashtagValue.replace(/<br>$/g, '');
            temp = hashtagValue.replace(/<br>$/g, '');
            el.innerHTML = normalizeForBlank(temp);
        }

        if (Object.keys(tags).some(key => key.toLowerCase() === hashKey.replace(/#/g, ''))) {
            if (el.firstChild.tagName === 'STRONG') {
                hashtagValue = '<strong>' + hashtagValue + '</strong>';
            }

            if (!el.attributes.id) {
                if (hashKey.toLowerCase().includes('clientfullname') && hashtagValue !== '__________') {
                    let client = clients.find(({ name }) => name === tags[hashKey.toLowerCase()]) || null;

                    if (!client) {
                        client = hashKey.toLowerCase() === 'hash-clientfullname2' ? clients[1] : clients[0];
                    }

                    hashtagValue = client && client.designation ? `${client.designation} ${hashtagValue}` : hashtagValue;
                }

                hashtagValue = '<span id="' + hashKey.replace(/#/g, '').toLowerCase() + '">' + hashtagValue + '</span>';
            }

            if (hashtagValue === '__________') {
                hashtagValue = `#${hashKey}#`;
            }

            // el.innerHTML = hashtagValue;
            temp = hashtagValue;
            el.innerHTML = normalizeForBlank(temp);
        } else {
            if (el.innerText.toLowerCase().includes('chk-')) {
                // el.innerHTML = el.innerHTML.replace(/\[.*?\](\s|&nbsp;)+/g, '[&nbsp;&nbsp;] ').replace(/\[.*?\]/g, '[&nbsp;&nbsp;] ');
                temp = el.innerHTML.replace(/\[.*?\](\s|&nbsp;)+/g, '[&nbsp;&nbsp;] ').replace(/\[.*?\]/g, '[&nbsp;&nbsp;] ');
                el.innerHTML = normalizeForBlank(temp);
            } else if (el.innerText.toLowerCase().includes(hashKey)) {
                const splitText = el.innerText.split('#');
                const keys = splitText.filter(item => Object.keys(tags).some(key => key.toLowerCase() === item.replace(/#/g, '').toLowerCase()));

                if (keys.length > 0) {
                    keys.forEach(key => {
                        const regex = new RegExp(`#${key}#`, 'i');
                        let value = tags[key.toLowerCase()] ? tags[key.toLowerCase()] : '__________';

                        if (key.toLowerCase().includes('clientfullname')) {
                            let client = clients.find(({ name }) => name === tags[key.toLowerCase()]) || null;

                            if (!client) {
                                client = key.toLowerCase() === 'hash-clientfullname2' ? clients[1] : clients[0];
                            }

                            value = client && client.designation ? `${client.designation} ${value}` : value;
                        }

                        if (value === '__________') {
                            value = `#${key}#`;
                        }

                        // el.innerHTML = el.innerHTML.replace(regex, '<span id="' + key.toLowerCase() + '">' + value + '</span>');
                        temp = el.innerHTML.replace(regex, '<span id="' + key.toLowerCase() + '">' + value + '</span>');
                        el.innerHTML = normalizeForBlank(temp);
                    });
                }
            }
        }
    } else {
        const textKey = el.innerText.toLowerCase();

        // isAppDebug && console.log(`PHASE1 TEXT: ${textKey}`);

        if (textKey.startsWith('#propery-agency-') && !textKey.startsWith('#propery-agency-city')) {
            let textTagValue = tags[textKey.replace(/#/g, '')] ? tags[textKey.replace(/#/g, '')].replace(/(<br>){2,}/g, '<br>') : '__________';

            if (textTagValue === '__________') {
                textTagValue = `#${textKey}#`;
            }

            // el.innerHTML = '<span id="' + textKey.replace(/#/g, '').toLowerCase() + '">' + textTagValue + '</span>';
            temp = '<span id="' + textKey.replace(/#/g, '').toLowerCase() + '">' + textTagValue + '</span>';
            el.innerHTML = normalizeForBlank(temp);
        } else if (textKey.startsWith('#propery-agency-city')) {
            const keys = textKey.split(' ');
            let textTagValue1 = tags[keys[0].replace(/#/g, '')] ? tags[keys[0].replace(/#/g, '')].replace(/(<br>){2,}/g, '<br>') : '__________';
            let textTagValue2 = tags[keys[1].replace(/#/g, '')] ? tags[keys[1].replace(/#/g, '')].replace(/(<br>){2,}/g, '<br>') : '__________';

            if (textTagValue1 === '__________') {
                textTagValue1 = `#${keys[0]}#`;
            }

            if (textTagValue2 === '__________') {
                textTagValue2 = `#${keys[1]}#`;
            }

            // el.innerHTML = '<span id="' + keys[0].replace(/#/g, '').toLowerCase() + '">' + textTagValue1 + '</span>&nbsp;<span id="' + keys[1].replace(/#/g, '').toLowerCase() + '">' + textTagValue2 + '</span>';
            temp = '<span id="' + keys[0].replace(/#/g, '').toLowerCase() + '">' + textTagValue1 + '</span>&nbsp;<span id="' + keys[1].replace(/#/g, '').toLowerCase() + '">' + textTagValue2 + '</span>';
            el.innerHTML = normalizeForBlank(temp);
        }
    }
}

// TODO: Why this function exists. Why the old developer not combining it to replaceHashtagsPhaseOne?
export function replaceHashtagsPhaseTwo(profileData, caseFile, content, tags) {
    const container = document.createElement('div');

    container.innerHTML = content;

    const list = Array.from(container.querySelectorAll('span, div'));

    isAppDebug && console.log(['PHASE2 LIST: ', list]);

    if (list.length > 0) {
        list.forEach((el) => {
            if (el.tagName === 'SPAN' && el.attributes.id) {
                const hash = el.attributes.id.value.toLowerCase();
                let value = tags[hash] ? tags[hash] : '__________';

                isAppDebug && console.log(`PHASE2 HASHTAG: <<${hash}>> ===> <<${value}>>`);

                if (hash.toLowerCase().includes('clientfullname')) {
                    const { clients } = caseFile;
                    let client = clients.find(({ name }) => name === tags[hash.toLowerCase()]) || null;

                    if (!client) {
                        client = hash.toLowerCase() === 'hash-clientfullname2' ? clients[1] : clients[0];
                    }

                    value = client && client.designation ? `${client.designation} ${value}` : value;
                }

                if (hash.includes('address')) {
                    value = value.replace(/(<br>){2,}/g, '<br>');
                }

                if (hash.toLowerCase().includes('buyername') && getRole(profileData.system_role) === 'Client') {
                    value = '';
                }

                if (el.firstChild && el.firstChild.tagName === 'STRONG') {
                    value = '<strong>' + value + '</strong>';
                }

                if (value === '__________') {
                    // value = `#${hash}#`;
                }

                // el.innerHTML = value;
                el.innerHTML = normalizeForBlank(value);
            }
        });
    }

    if (isAppDebug) {
        return container.innerHTML;
    } else {
        return container.innerHTML.replace(/#Hash-[a-zA-Z0-9]+#/gi, '__________');
    }
}

/**
 * Fixed spaces and text
 * @param {Object} el
 */
export function elementFix(el) {
    // check if beginning text should be [ ]
    if (/\[.*?\](\s|&nbsp;)+/g.test(el.innerText) || /\[.*?\][a-z0-9]/g.test(el.innerText)) {
        el.innerHTML = el.innerHTML.replace(/\[.*?\](\s|&nbsp;)+/g, '[&nbsp;&nbsp;] ').replace(/\[.*?\]/g, '[&nbsp;&nbsp;] ');
    } else if (el.firstElementChild && (/\[.*?\](\s|&nbsp;)+/g.test(el.firstElementChild.innerText) || /\[.*?\][a-z0-9]/g.test(el.firstElementChild.innerText))) {
        el.firstElementChild.innerHTML = el.firstElementChild.innerHTML.replace(/\[.*?\](\s|&nbsp;)+/g, '[&nbsp;&nbsp;] ').replace(/\[.*?\]/g, '[&nbsp;&nbsp;] ');
    }

    // insert spaces before and after
    if (el.children.length) {
        const children = Array.from(el.children);

        children.forEach((child, i) => {
            if (child.style.fontWeight === 'bold' || child.style.textDecoration || child.style.fontSize === '10pt') {
                if (children[i - 1] && !/[,:;!?.%\"\”\“)\s]$/.test(children[i - 1].innerText)) {
                    children[i - 1].innerText = `${children[i - 1].innerText} `;
                }

                if (children[i + 1] && !/^[,:;!?.%\"\”\“)\s]/.test(children[i + 1].innerText)) {
                    children[i + 1].innerText = ` ${children[i + 1].innerText}`;
                }
            }

            if (/\[.*?\](\s|&nbsp;)+/.test(child.innerText) || /\[.*?\][a-z0-9]/.test(child.innerText) || /\[.*?\]/.test(child.innerText)) {
                el.style.textAlign = 'left';
                child.innerHTML = child.innerHTML.replace(/\[.*?\](\s|&nbsp;)+/, '[&nbsp;&nbsp;] ').replace(/\[.*?\]/, '[&nbsp;&nbsp;] ');
            }
        });
    }

    // check if should insert space after period
    if (/^[a-z0-9][.][[a-z0-9]/i.test(el.innerText)) {
        el.innerText = el.innerText.replace('.', '. ');
    } else if (el.firstElementChild && /^[a-z0-9][.][[a-z0-9]/i.test(el.firstElementChild.innerText)) {
        el.firstElementChild.innerText = el.firstElementChild.innerText.replace('.', '. ');
    }
}

/**
 * Convert class to inline style
 * @param {String} content
 * @returns {String}
 */
export function prepareContentForPdf(content) {
    const doc = document.createElement('div');

    doc.innerHTML = content;

    const list = Array.from(doc.querySelectorAll('mark, figure, blockquote, hr, div, p, span, img, section, strong'));

    if (list.length > 0) {
        list.forEach((el) => {
            if (el.tagName === 'MARK' && !/^[ ]+$/.test(el.innerText)) {
                if (el.classList.value === 'pen-red') {
                    el.style.color = '#e91313';
                    el.style.backgroundColor = 'transparent';
                } else if (el.classList.value === 'pen-green') {
                    el.style.color = '#180';
                    el.style.backgroundColor = 'transparent';
                } else if (el.classList.value === 'marker-yellow') {
                    el.style.backgroundColor = '#fdfd77';
                } else if (el.classList.value === 'marker-green') {
                    el.style.backgroundColor = '#63f963';
                } else if (el.classList.value === 'marker-pink') {
                    el.style.backgroundColor = '#fc7999';
                } else if (el.classList.value === 'marker-blue') {
                    el.style.backgroundColor = '#72cdfd';
                }
            }

            if (el.tagName === 'FIGURE') {
                el.style.overflow = 'hidden';

                if (el.classList.value === 'image image-style-align-left') {
                    el.style.float = 'left';
                    el.style.marginLeft = '0';
                    el.style.marginTop = '0';
                } else if (el.classList.value === 'image image-style-align-right') {
                    el.style.float = 'right';
                    el.style.margin = '0';
                    el.firstElementChild.style.display = 'block';
                } else {
                    el.firstElementChild.style.margin = '0 auto';
                    el.firstElementChild.style.maxWidth = '100%';
                    el.firstElementChild.style.textAlign = 'center';
                }
            }

            if (el.tagName === 'SECTION') {
                el.style.maxWidth = '100%';
                el.style.width = '100%';
            }

            if (el.tagName === 'DIV') {
                if (['simple-box-left-description', 'simple-box-right-description'].includes(el.classList.value)) {
                    el.style.display = 'inline-block';
                    el.style.width = '49.6%';
                    el.style.verticalAlign = 'top';
                    el.style.padding = '0';
                    el.style.margin = '0';
                } else if (el.classList.value === 'horizontal-rule') {
                    el.style.borderBottom = '2px solid #333';
                    el.style.width = '100%';
                    el.style.height = '2px';
                    el.style.margin = '5px 0';
                }
            }

            if (el.tagName === 'BLOCKQUOTE') {
                el.style.borderLeft = '4px solid #ccc';
                el.style.marginBottom = '5px';
                el.style.marginTop = '5px';
                el.style.paddingLeft = '16px';
            }

            if (el.tagName === 'STRONG' && ['LI', 'P'].includes(el.parentNode.nodeName)) {
                el.style.marginLeft = '2px';
                el.style.marginRight = '2px';
            }

            if (el.tagName === 'P' || el.tagName === 'SPAN') {
                el.style.marginTop = '0';
                el.style.marginBottom = '0';
                el.style.marginLeft = el.tagName === 'SPAN' ? '2px' : '0';

                if (el.firstElementChild) {
                    if (el.firstElementChild.classList.value.includes('text-huge')) {
                        el.style.fontSize = '19px';
                    } else if (el.firstElementChild.classList.value.includes('text-big')) {
                        el.style.fontSize = '17px';
                    } else if (el.firstElementChild.classList.value.includes('text-small')) {
                        el.style.lineHeight = '1';
                        el.style.fontSize = '13px';
                    } else if (el.firstElementChild.classList.value.includes('text-tiny')) {
                        el.style.lineHeight = '0.9';
                        el.style.fontSize = '11px';
                    }
                }
            }
        });
    }

    return doc.innerHTML;
}

/**
 * Wrap html with MLS pdf header
 * @param {String} newContent
 * @returns {String}
 */
export async function wrapContent(newContent) {
    const content = prepareContentForPdf(newContent);
    // It doesn't work in staging
    // const css = await getPdfCss();

    return `<html>
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <style>

            </style>
        </head>
        <body style="background-color: white; border:0; margin: 0; width: 100%;">
            <div class-"container" style="padding: 0 2cm;">
            ${content}
            </div>
            <script>
                const mainHeader = document.getElementsByClassName('content-main')[0];
                const secondaryHeader = document.getElementsByClassName('content-secondary')[0];
                mainHeader.style.width = '300px !important';
                secondaryHeader.style.width = '300px !important';

                const container = document.getElementsByClassName('container')[0];
                container.style.marginTop = '300px';
                container.style.backgroundCOlor = 'red';
            </script>
        </body>
        </html>`;
}

/**
 * Get string for last user to edit template
 * @param {Array} staffs
 * @param {Object} template
 * @param {String} caseRef
 * @returns {String}
 */
export function getLastUpdate(staffs, template, caseRef) {
    if (!template.last_updated_by) {
        return '';
    }

    const user = staffs.find(({ id }) => id === template.last_updated_by) || null;
    const date = format(getTimeFromServerTime(template.updated_at), 'HH:mm, MMM D, YYYY');

    if (!user) {
        return `Last Edit Made at ${date}`;
    }

    const username = user.name ? user.name : user.email;
    const code = username.split(' ').map((n) => n[0]).join('');

    return `Last Edit Made by ${username} at ${date} (${code}-${caseRef})`;
}

/**
 * Create html for header / footer
 * @param {Array} content
 * @param {Boolean} isHeader
 * @returns {String}
 */
export async function createLetterhead(content, isHeader) {
    let data = '';
    const pageText = `<p style="text-align:right; font-size: 13px;">
        Page <span class="page"></span> of <span class="topage"></span>
    </p>`;

    content && content.forEach(item => {
        data += `<div
            class="${`content-${item.isMain ? 'main' : 'secondary'}
            ${isHeader ? '-header' : '-footer'}`}"
            style="${item.isFullWidth ? 'padding: 0;' : 'padding: 0 2cm 0 2cm;'}">
                ${prepareContentForPdf(item.content)}
                ${(isHeader && !item.isMain) ? pageText : ''}
        </div>`;
    });

    // It doesn't work in staging
    // const css = await getPdfCss();

    return `<!DOCTYPE html>
    <html>
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <style>
        .content-main.-header > figure {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }

        .content-secondary.-header > figure {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }

        .content-main.-footer > figure {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }

        .content-secondary.-footer > figure {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }
        </style>
    </head>
    <body style="background-color: white; border:0; margin: 0; padding: 0; width: 100%;">
        ${data}
        <script>
            var vars = {};
            var query_strings_from_url = document.location.search.substring(1).split('&');
            for (var query_string in query_strings_from_url) {
                if (query_strings_from_url.hasOwnProperty(query_string)) {
                    var temp_var = query_strings_from_url[query_string].split('=', 2);
                    vars[temp_var[0]] = decodeURI(temp_var[1]);
                }
            }
            var css_selector_classes = ['page', 'frompage', 'topage', 'webpage', 'section', 'subsection', 'date', 'isodate', 'time', 'title', 'doctitle', 'sitepage', 'sitepages'];
            for (var css_class in css_selector_classes) {
                if (css_selector_classes.hasOwnProperty(css_class)) {
                    var element = document.getElementsByClassName(css_selector_classes[css_class]);

                    for (var j = 0; j < element.length; ++j) {
                        element[j].textContent = vars[css_selector_classes[css_class]];
                    }

                    if (css_selector_classes[css_class] === 'page') {
                        const main = document.getElementsByClassName('content-main')[0];
                        const secondary = document.getElementsByClassName('content-secondary')[0];
                        // If page number 1
                        if (vars[css_selector_classes[css_class]] === '1') {
                            main.style.display = 'block';
                            secondary.style.display = 'none';
                        }
                        else {
                            main.style.display = 'none';
                            secondary.style.display = 'block';
                        }
                    }
                }
            }
        </script>
    </body>
    </html>`;
}

/**
 * Download a file / folder
 * @param {String} fileId - needed when downloading a file
 * @param {String} fileName
 * @param {String} url - the download url (mostly provided for folder downloads)
 * @param {String} isTargetBlank - set target='_blank'
 */
export function download(fileId, fileName, url, isTargetBlank) {
    const link = document.createElement('a');

    if (isTargetBlank) {
        link.target = '_blank';
    } else {
        link.download = fileName;
    }

    link.href = url || `${apiUrl}/file/${fileId}/download?token=${getLoginToken()}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * Render address as String
 * Address can be String or JSON Array
 * @param {Object} address
 * @returns {Object}
 */
export function renderAddress(address) {
    if (address) {
        try {
            address = JSON.parse(address);
        } catch (error) {
            // This error is a sign that address is in STRING format (not a valid json Array)
        }

        if (Array.isArray(address)) {
            address = `${address[0] || ''} ${address[1] || ''} ${address[2] || ''} ${address[3] || ''} ${address[4] || ''}`;
        }
    } else {
        address = '';
    }

    return address;
}

/**
 * Render address as Object.
 * Address can be String or JSON Array
 * @param {Object} address
 * @returns {Object}
 */
export function renderAddressAsObject(address) {
    if (address) {
        try {
            address = JSON.parse(address);
        } catch (error) {
            // This error is a sign that address is in STRING format (not a valid json Array)
            address = { line1: '', line2: '', city: '', postalCode: '', country: '' };
        }

        if (Array.isArray(address)) {
            address = {
                line1: address[0] || '',
                line2: address[1] || '',
                city: address[2] || '',
                postalCode: address[3] || '',
                country: address[4] || ''
            };
        }
    } else {
        address = { line1: '', line2: '', city: '', postalCode: '', country: '' };
    }

    return address;
}

/**
 * Parse address
 * @param {String} address
 * @returns {String}
 */
export function parseAddress(address) {
    if (!address) {
        return '';
    }

    return address.replace(/<br>/g, ' ').replace(/\s{2}/g, ' ');
}

/**
 * Parse address as object
 * @param {String} address
 * @returns {String}
 */
export function parseAddressAsObject(address) {
    if (address) {
        try {
            address = address.split('<br>');
            address = {
                line1: address[0] || '',
                line2: address[1] || '',
                city: address[2] || '',
                postalCode: address[3] || '',
                country: address[4] || ''
            };
        } catch {
            address = { line1: '', line2: '', city: '', postalCode: '', country: '' };
        }
    } else {
        address = { line1: '', line2: '', city: '', postalCode: '', country: '' };
    }

    return address;
}

/**
 * Make address into multiline
 * @param {String} line1
 * @param {String} line2
 * @param {String} city
 * @param {String} postalCode
 * @param {String} country
 * @returns {String}
 */
export function makeMultilineAddress(line1, line2, city, postalCode, country) {
    return `${line1}<br>${line2}<br>${city}<br>${postalCode}<br>${country}<br>`;
}

export const LocalStorage = {
    get(key) {
        return JSON.parse(window.localStorage.getItem(key));
    },

    set(key, data) {
        return window.localStorage.setItem(key, JSON.stringify(data));
    }
};

/**
 * Format a ISO date string into a relative time with respect to Singapore timezone
 * @param {String} dateString - the date string based on Singapore timezone
 * @param {Number} currentTime - the current time in ms based on local timezone
 * @param {Boolean} isTask
 * @returns {String}
 */
export function getRelativeTime(dateString, currentTime = Date.now(), isTask) {
    // time difference calculations are done relative to UTC, Singapore is 8 hours ahead of UTC
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const timestamp = Date.parse(dateString.replace(/-/g, '/'));
    const timezoneOffset = (((new Date()).getTimezoneOffset() / 60) + 8) * msPerHour;
    const elapsed = Math.abs(currentTime + timezoneOffset - timestamp);

    let returnDate,
        unit;

    if (elapsed < msPerMinute) {
        returnDate = Math.floor(elapsed / 1000);
        unit = 'second';
    } else if (elapsed < msPerHour) {
        returnDate = Math.floor(elapsed / msPerMinute);
        unit = 'minute';
    } else if (elapsed < msPerDay) {
        returnDate = Math.floor(elapsed / msPerHour);
        unit = 'hour';
    } else {
        returnDate = Math.round(elapsed / msPerDay);
        unit = 'day';
    }

    const isDateAhead = isTask && (new Date(dateString).getTime() > currentTime);

    return `${returnDate} ${unit}${returnDate > 1 ? 's' : ''} ${isDateAhead ? 'to go' : 'ago'}`;
}

async function getPdfCss() {
    if (!Window.PDF_CSS) {
        Window.PDF_CSS = await fetch(`${document.location.origin}/${pdfCss}`)
            .then(data => data.text());
    }

    return Window.PDF_CSS;
}

// Assuming timezone is Asia/Singapore (UTC+8)
// Assuming time string is like: 2001-01-01 12:00:00
export function getTimeFromServerTime(timeString, { fromTimeZone = 'UTC+8', isFormatted = false, formatString = 'YYYY-MM-DD HH:mm:ss' } = {}) {
    // Create a Date object from the server time string with the specified timezone
    const time = new Date(`${timeString} ${fromTimeZone}`);

    // Check if the Date object is valid
    if (isNaN(time.getTime())) {
        throw new Error('Invalid date format');
    }

    if (isFormatted) {
        return format(time, formatString);
    }

    return time;
}

export function normalizeForBlank(value) {
    return RENDER_BLANK_REGEX.test(value) ? '' : value;
}
