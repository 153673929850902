export const isProduction = process.env.REACT_APP_MODE === 'production';

export const isOffline = process.env.REACT_APP_API_MODE === 'offline';

export const isAppDebug = process.env.REACT_APP_DEBUG === 'true';

export const apiUrl = process.env.REACT_APP_API_URL;

export const pusherKey = isOffline
    ? '123123123'
    : isProduction
        ? 'ac3ce86e9c9414d67d9f'
        : '05eb36c47135156e4e1c';

export const loginTokenStorageItem = 'LegalAssizLoginToken';

export const colorBrandActive = '#ccbe88';

export const workspaceUrlPrefix = '/app';

const ckEditorDomain = '38347.cke-cs.com';

export const cloudServices = {
    // replace with real tokenUrl
    tokenUrl: `https://${ckEditorDomain}/token/dev/mds3Wee2DUjFVdGZMfvvGHbW8QLc8dah1dlAqBAGsTqGGZyvZYAD1u7tVZ8b`,
    uploadUrl: `https://${ckEditorDomain}/easyimage/upload/`,
    webSocketUrl: `${ckEditorDomain}/ws`
};

export const systemRoles = {
    ADMIN: 1,
    SECRETARY: 2,
    LAWYER: 3,
    CLIENT: 4
};

export const caseStatuses = {
    ACTIVE: 1,
    CLOSED: 2
};

export const notificationTypes = {
    taskDue: 'App\\Notifications\\TaskDueNotification',
    caseFile: 'App\\Notifications\\AddedToCaseFileNotification',
    fileAdded: 'App\\Notifications\\NewFileAddedNotification'
};
